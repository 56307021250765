<template>
    <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Login</div>
          Der Login ist nur mit einem Google-Konto möglich.
        </q-card-section>
        <q-card-section class="text-center">
            <!-- Mit One Tap -->
            <!-- <GoogleLogin :callback="handleLogin" prompt/> -->

            <!-- Mit Auto-Login -->
            <GoogleLogin :callback="handleLogin" prompt auto-login/>
            
            <!-- Ohne One Tap -->
            <!-- <GoogleLogin :callback="handleLogin"/> -->
        </q-card-section>         

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Schließen" v-close-popup />
        </q-card-actions>
      </q-card>
</template>


<script>
import { useQuasar } from "quasar";

export default {
  name: 'App',

   setup() {
    const $q = useQuasar();
    return {
      showNotif(msg, color) {
        $q.notify({
          message: msg,
          color: color,
        });
      },
    };
  },


  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL, //`${this.apiUrl}`
    };
  },
  methods: {
    async handleLogin(response) {  //Aufruf, wenn mit Google loggined

      try {
        const res = await this.axios.post(`${this.apiUrl}/loginUser`, {
        token: response.credential,  //schickt Token ans Backend, dort wird er im Backend verifiziert
        });

        if(res.data.auth == true){  //wenn Backend das okey gibt, speichere Daten zwischen
        const cookie = res.data.hashedEmail + " " + res.data.currentRefreshToken;
        this.$cookies.set("user", cookie, "7d", null, null, true);
        const localStoragePayload = { loggedIn: res.data.auth, givenName: res.data.givenName };
        localStorage.setItem("user", JSON.stringify(localStoragePayload));
        this.$store.commit("changeLoggedIn", res.data.auth);
        this.$store.commit("changeGivenName", res.data.givenName);
        this.$emit('already-logged-in', res.data.stillLoggedIn);
        if(!res.data.userExists){
          await this.$router.push("/calendar"); //Später: /gettingStarted
        }
        else {
          await this.$router.push("/calendar");
        }
        }
      } catch (error) {
        this.showNotif("Keine Verbinung zum Server.", "negative");
      }
    },
  },

}
</script>

<style>
</style>